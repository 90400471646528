@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;900&display=swap");

html,
body,
#app,
.cd {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cd {
  &__wrapper {
    background: rgba(#000, 0.7);
    color: darken(#fff, 5%);
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  &__time-left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (orientation: landscape) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__time-detail {
    text-align: center;
  }
}

.time {
  font-weight: 900;
  display: block;
  font-size: 5rem;
  line-height: 1.2;

  @media screen and (min-width: 1200px) {
    font-size: 10rem;
  }
}

.label {
  font-size: 1.5rem;
  display: block;
  text-align: center;
}

.congrats {
  animation: pulseEnjoy 1s ease-in-out;
  animation-iteration-count: infinite;
  font-size: 4rem;
  text-align: center;
  font-weight: 900;

  &__fade {
    animation: wipeOut 1s ease-in-out 0.25s;
    animation-iteration-count: infinite;
    position: absolute;
    font-size: 4rem;
    text-align: center;
    font-weight: 900;
    opacity: 0;
  }
}

@keyframes wipeOut {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(5);
  }
}

@keyframes pulseEnjoy {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
